export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCES = "REGISTER_SUCCES";

export const REGISTER_TEAM_SUCCESS = "REGISTER_TEAM_SUCCESS";
export const REGISTER_TEAM_FAIL = "REGISTER_TEAM_FAIL";
export const LOGIN_TEAM_SUCCESS = "LOGIN_TEAM_SUCCESS"
export const LOGIN_TEAM_FAIL = "LOGIN_TEAM_FAIL";
export const TEAM_RESULTS_RECEIVED = "TEAM_RESULTS_RECEIVED";
export const TEAM_RESULTS_RECEIVED_FAILED = "TEAM_RESULTS_RECEIVED_FAILED";
export const USERS_TEAMS_FOUND = "USERS_TEAMS_FOUND";
export const USERS_TEAMS_FOUND_FAILED = "USERS_TEAMS_FOUND_FAILED";
export const SET_TEAM_NAME = "SET_TEAM_NAME";

export const SAVE_USER_RESULT = "SAVE_USER_RESULT";
export const SAVE_RANDOM_RESULT = "SAVE_RANDOM_RESULT";
export const SAVE_FAILED = "SAVE_FAILED";
export const SAVE_TEAM_RESULT = "SAVE_TEAM_RESULT"
