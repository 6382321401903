const data = [
  {
    id: 1,
    imgPathFront: "/images/imgPathFront_Page_001.png",
    imgPathBack: "/images/imgPathBack_Page_001.png",
    haltung: 1,
    quadrant: 1
  }, {
    id: 2,
    imgPathFront: "/images/imgPathFront_Page_017.png",
    imgPathBack: "/images/imgPathBack_Page_017.png",
    haltung: 2,
    quadrant: 1
  }, {
    id: 3,
    imgPathFront: "/images/imgPathFront_Page_033.png",
    imgPathBack: "/images/imgPathBack_Page_033.png",
    haltung: 3,
    quadrant: 1
  }, {
    id: 4,
    imgPathFront: "/images/imgPathFront_Page_049.png",
    imgPathBack: "/images/imgPathBack_Page_049.png",
    haltung: 4,
    quadrant: 1
  }, {
    id: 5,
    imgPathFront: "/images/imgPathFront_Page_065.png",
    imgPathBack: "/images/imgPathBack_Page_065.png",
    haltung: 5,
    quadrant: 1
  }, {
    id: 6,
    imgPathFront: "/images/imgPathFront_Page_081.png",
    imgPathBack: "/images/imgPathBack_Page_081.png",
    haltung: 6,
    quadrant: 1
  }, {
    id: 7,
    imgPathFront: "/images/imgPathFront_Page_002.png",
    imgPathBack: "/images/imgPathBack_Page_002.png",
    haltung: 1,
    quadrant: 1
  }, {
    id: 8,
    imgPathFront: "/images/imgPathFront_Page_018.png",
    imgPathBack: "/images/imgPathBack_Page_018.png",
    haltung: 2,
    quadrant: 1
  }, {
    id: 9,
    imgPathFront: "/images/imgPathFront_Page_034.png",
    imgPathBack: "/images/imgPathBack_Page_034.png",
    haltung: 3,
    quadrant: 1
  }, {
    id: 10,
    imgPathFront: "/images/imgPathFront_Page_050.png",
    imgPathBack: "/images/imgPathBack_Page_050.png",
    haltung: 4,
    quadrant: 1
  }, {
    id: 11,
    imgPathFront: "/images/imgPathFront_Page_066.png",
    imgPathBack: "/images/imgPathBack_Page_066.png",
    haltung: 5,
    quadrant: 1
  }, {
    id: 12,
    imgPathFront: "/images/imgPathFront_Page_082.png",
    imgPathBack: "/images/imgPathBack_Page_082.png",
    haltung: 6,
    quadrant: 1
  }, {
    id: 13,
    imgPathFront: "/images/imgPathFront_Page_003.png",
    imgPathBack: "/images/imgPathBack_Page_003.png",
    haltung: 1,
    quadrant: 1
  }, {
    id: 14,
    imgPathFront: "/images/imgPathFront_Page_019.png",
    imgPathBack: "/images/imgPathBack_Page_019.png",
    haltung: 2,
    quadrant: 1
  }, {
    id: 15,
    imgPathFront: "/images/imgPathFront_Page_035.png",
    imgPathBack: "/images/imgPathBack_Page_035.png",
    haltung: 3,
    quadrant: 1
  }, {
    id: 16,
    imgPathFront: "/images/imgPathFront_Page_051.png",
    imgPathBack: "/images/imgPathBack_Page_051.png",
    haltung: 4,
    quadrant: 1
  }, {
    id: 17,
    imgPathFront: "/images/imgPathFront_Page_067.png",
    imgPathBack: "/images/imgPathBack_Page_067.png",
    haltung: 5,
    quadrant: 1
  }, {
    id: 18,
    imgPathFront: "/images/imgPathFront_Page_083.png",
    imgPathBack: "/images/imgPathBack_Page_083.png",
    haltung: 6,
    quadrant: 1
  }, {
    id: 19,
    imgPathFront: "/images/imgPathFront_Page_004.png",
    imgPathBack: "/images/imgPathBack_Page_004.png",
    haltung: 1,
    quadrant: 1
  }, {
    id: 20,
    imgPathFront: "/images/imgPathFront_Page_020.png",
    imgPathBack: "/images/imgPathBack_Page_020.png",
    haltung: 2,
    quadrant: 1
  }, {
    id: 21,
    imgPathFront: "/images/imgPathFront_Page_036.png",
    imgPathBack: "/images/imgPathBack_Page_036.png",
    haltung: 3,
    quadrant: 1
  }, {
    id: 22,
    imgPathFront: "/images/imgPathFront_Page_052.png",
    imgPathBack: "/images/imgPathBack_Page_052.png",
    haltung: 4,
    quadrant: 1
  }, {
    id: 23,
    imgPathFront: "/images/imgPathFront_Page_068.png",
    imgPathBack: "/images/imgPathBack_Page_068.png",
    haltung: 5,
    quadrant: 1
  }, {
    id: 24,
    imgPathFront: "/images/imgPathFront_Page_084.png",
    imgPathBack: "/images/imgPathBack_Page_084.png",
    haltung: 6,
    quadrant: 1
  }, {
    id: 25,
    imgPathFront: "/images/imgPathFront_Page_005.png",
    imgPathBack: "/images/imgPathBack_Page_005.png",
    haltung: 1,
    quadrant: 2
  }, {
    id: 26,
    imgPathFront: "/images/imgPathFront_Page_021.png",
    imgPathBack: "/images/imgPathBack_Page_021.png",
    haltung: 2,
    quadrant: 2
  }, {
    id: 27,
    imgPathFront: "/images/imgPathFront_Page_037.png",
    imgPathBack: "/images/imgPathBack_Page_037.png",
    haltung: 3,
    quadrant: 2
  }, {
    id: 28,
    imgPathFront: "/images/imgPathFront_Page_053.png",
    imgPathBack: "/images/imgPathBack_Page_053.png",
    haltung: 4,
    quadrant: 2
  }, {
    id: 29,
    imgPathFront: "/images/imgPathFront_Page_069.png",
    imgPathBack: "/images/imgPathBack_Page_069.png",
    haltung: 5,
    quadrant: 2
  }, {
    id: 30,
    imgPathFront: "/images/imgPathFront_Page_085.png",
    imgPathBack: "/images/imgPathBack_Page_085.png",
    haltung: 6,
    quadrant: 2
  }, {
    id: 31,
    imgPathFront: "/images/imgPathFront_Page_006.png",
    imgPathBack: "/images/imgPathBack_Page_006.png",
    haltung: 1,
    quadrant: 2
  }, {
    id: 32,
    imgPathFront: "/images/imgPathFront_Page_022.png",
    imgPathBack: "/images/imgPathBack_Page_022.png",
    haltung: 2,
    quadrant: 2
  }, {
    id: 33,
    imgPathFront: "/images/imgPathFront_Page_038.png",
    imgPathBack: "/images/imgPathBack_Page_038.png",
    haltung: 3,
    quadrant: 2
  }, {
    id: 34,
    imgPathFront: "/images/imgPathFront_Page_054.png",
    imgPathBack: "/images/imgPathBack_Page_054.png",
    haltung: 4,
    quadrant: 2
  }, {
    id: 35,
    imgPathFront: "/images/imgPathFront_Page_070.png",
    imgPathBack: "/images/imgPathBack_Page_070.png",
    haltung: 5,
    quadrant: 2
  }, {
    id: 36,
    imgPathFront: "/images/imgPathFront_Page_086.png",
    imgPathBack: "/images/imgPathBack_Page_086.png",
    haltung: 6,
    quadrant: 2
  }, {
    id: 37,
    imgPathFront: "/images/imgPathFront_Page_007.png",
    imgPathBack: "/images/imgPathBack_Page_007.png",
    haltung: 1,
    quadrant: 2
  }, {
    id: 38,
    imgPathFront: "/images/imgPathFront_Page_023.png",
    imgPathBack: "/images/imgPathBack_Page_023.png",
    haltung: 2,
    quadrant: 2
  }, {
    id: 39,
    imgPathFront: "/images/imgPathFront_Page_039.png",
    imgPathBack: "/images/imgPathBack_Page_039.png",
    haltung: 3,
    quadrant: 2
  }, {
    id: 40,
    imgPathFront: "/images/imgPathFront_Page_055.png",
    imgPathBack: "/images/imgPathBack_Page_055.png",
    haltung: 4,
    quadrant: 2
  }, {
    id: 41,
    imgPathFront: "/images/imgPathFront_Page_071.png",
    imgPathBack: "/images/imgPathBack_Page_071.png",
    haltung: 5,
    quadrant: 2
  }, {
    id: 42,
    imgPathFront: "/images/imgPathFront_Page_087.png",
    imgPathBack: "/images/imgPathBack_Page_087.png",
    haltung: 6,
    quadrant: 2
  }, {
    id: 43,
    imgPathFront: "/images/imgPathFront_Page_008.png",
    imgPathBack: "/images/imgPathBack_Page_008.png",
    haltung: 1,
    quadrant: 2
  }, {
    id: 44,
    imgPathFront: "/images/imgPathFront_Page_024.png",
    imgPathBack: "/images/imgPathBack_Page_024.png",
    haltung: 2,
    quadrant: 2
  }, {
    id: 45,
    imgPathFront: "/images/imgPathFront_Page_040.png",
    imgPathBack: "/images/imgPathBack_Page_040.png",
    haltung: 3,
    quadrant: 2
  }, {
    id: 46,
    imgPathFront: "/images/imgPathFront_Page_056.png",
    imgPathBack: "/images/imgPathBack_Page_056.png",
    haltung: 4,
    quadrant: 2
  }, {
    id: 47,
    imgPathFront: "/images/imgPathFront_Page_072.png",
    imgPathBack: "/images/imgPathBack_Page_072.png",
    haltung: 5,
    quadrant: 2
  }, {
    id: 48,
    imgPathFront: "/images/imgPathFront_Page_088.png",
    imgPathBack: "/images/imgPathBack_Page_088.png",
    haltung: 6,
    quadrant: 2
  }, {
    id: 49,
    imgPathFront: "/images/imgPathFront_Page_009.png",
    imgPathBack: "/images/imgPathBack_Page_009.png",
    haltung: 1,
    quadrant: 3
  }, {
    id: 50,
    imgPathFront: "/images/imgPathFront_Page_025.png",
    imgPathBack: "/images/imgPathBack_Page_025.png",
    haltung: 2,
    quadrant: 3
  }, {
    id: 51,
    imgPathFront: "/images/imgPathFront_Page_041.png",
    imgPathBack: "/images/imgPathBack_Page_041.png",
    haltung: 3,
    quadrant: 3
  }, {
    id: 52,
    imgPathFront: "/images/imgPathFront_Page_057.png",
    imgPathBack: "/images/imgPathBack_Page_057.png",
    haltung: 4,
    quadrant: 3
  }, {
    id: 53,
    imgPathFront: "/images/imgPathFront_Page_073.png",
    imgPathBack: "/images/imgPathBack_Page_073.png",
    haltung: 5,
    quadrant: 3
  }, {
    id: 54,
    imgPathFront: "/images/imgPathFront_Page_089.png",
    imgPathBack: "/images/imgPathBack_Page_089.png",
    haltung: 6,
    quadrant: 3
  }, {
    id: 55,
    imgPathFront: "/images/imgPathFront_Page_010.png",
    imgPathBack: "/images/imgPathBack_Page_010.png",
    haltung: 1,
    quadrant: 3
  }, {
    id: 56,
    imgPathFront: "/images/imgPathFront_Page_026.png",
    imgPathBack: "/images/imgPathBack_Page_026.png",
    haltung: 2,
    quadrant: 3
  }, {
    id: 57,
    imgPathFront: "/images/imgPathFront_Page_042.png",
    imgPathBack: "/images/imgPathBack_Page_042.png",
    haltung: 3,
    quadrant: 3
  }, {
    id: 58,
    imgPathFront: "/images/imgPathFront_Page_058.png",
    imgPathBack: "/images/imgPathBack_Page_058.png",
    haltung: 4,
    quadrant: 3
  }, {
    id: 59,
    imgPathFront: "/images/imgPathFront_Page_074.png",
    imgPathBack: "/images/imgPathBack_Page_074.png",
    haltung: 5,
    quadrant: 3
  }, {
    id: 60,
    imgPathFront: "/images/imgPathFront_Page_090.png",
    imgPathBack: "/images/imgPathBack_Page_090.png",
    haltung: 6,
    quadrant: 3
  }, {
    id: 61,
    imgPathFront: "/images/imgPathFront_Page_011.png",
    imgPathBack: "/images/imgPathBack_Page_011.png",
    haltung: 1,
    quadrant: 3
  }, {
    id: 62,
    imgPathFront: "/images/imgPathFront_Page_027.png",
    imgPathBack: "/images/imgPathBack_Page_027.png",
    haltung: 2,
    quadrant: 3
  }, {
    id: 63,
    imgPathFront: "/images/imgPathFront_Page_043.png",
    imgPathBack: "/images/imgPathBack_Page_043.png",
    haltung: 3,
    quadrant: 3
  }, {
    id: 64,
    imgPathFront: "/images/imgPathFront_Page_059.png",
    imgPathBack: "/images/imgPathBack_Page_059.png",
    haltung: 4,
    quadrant: 3
  }, {
    id: 65,
    imgPathFront: "/images/imgPathFront_Page_075.png",
    imgPathBack: "/images/imgPathBack_Page_075.png",
    haltung: 5,
    quadrant: 3
  }, {
    id: 66,
    imgPathFront: "/images/imgPathFront_Page_091.png",
    imgPathBack: "/images/imgPathBack_Page_091.png",
    haltung: 6,
    quadrant: 3
  }, {
    id: 67,
    imgPathFront: "/images/imgPathFront_Page_012.png",
    imgPathBack: "/images/imgPathBack_Page_012.png",
    haltung: 1,
    quadrant: 3
  }, {
    id: 68,
    imgPathFront: "/images/imgPathFront_Page_028.png",
    imgPathBack: "/images/imgPathBack_Page_028.png",
    haltung: 2,
    quadrant: 3
  }, {
    id: 69,
    imgPathFront: "/images/imgPathFront_Page_044.png",
    imgPathBack: "/images/imgPathBack_Page_044.png",
    haltung: 3,
    quadrant: 3
  }, {
    id: 70,
    imgPathFront: "/images/imgPathFront_Page_060.png",
    imgPathBack: "/images/imgPathBack_Page_060.png",
    haltung: 4,
    quadrant: 3
  }, {
    id: 71,
    imgPathFront: "/images/imgPathFront_Page_076.png",
    imgPathBack: "/images/imgPathBack_Page_076.png",
    haltung: 5,
    quadrant: 3
  }, {
    id: 72,
    imgPathFront: "/images/imgPathFront_Page_092.png",
    imgPathBack: "/images/imgPathBack_Page_092.png",
    haltung: 6,
    quadrant: 3
  }, {
    id: 73,
    imgPathFront: "/images/imgPathFront_Page_013.png",
    imgPathBack: "/images/imgPathBack_Page_013.png",
    haltung: 1,
    quadrant: 4
  }, {
    id: 74,
    imgPathFront: "/images/imgPathFront_Page_029.png",
    imgPathBack: "/images/imgPathBack_Page_029.png",
    haltung: 2,
    quadrant: 4
  }, {
    id: 75,
    imgPathFront: "/images/imgPathFront_Page_045.png",
    imgPathBack: "/images/imgPathBack_Page_045.png",
    haltung: 3,
    quadrant: 4
  }, {
    id: 76,
    imgPathFront: "/images/imgPathFront_Page_061.png",
    imgPathBack: "/images/imgPathBack_Page_061.png",
    haltung: 4,
    quadrant: 4
  }, {
    id: 77,
    imgPathFront: "/images/imgPathFront_Page_077.png",
    imgPathBack: "/images/imgPathBack_Page_077.png",
    haltung: 5,
    quadrant: 4
  }, {
    id: 78,
    imgPathFront: "/images/imgPathFront_Page_093.png",
    imgPathBack: "/images/imgPathBack_Page_093.png",
    haltung: 6,
    quadrant: 4
  }, {
    id: 79,
    imgPathFront: "/images/imgPathFront_Page_014.png",
    imgPathBack: "/images/imgPathBack_Page_014.png",
    haltung: 1,
    quadrant: 4
  }, {
    id: 80,
    imgPathFront: "/images/imgPathFront_Page_030.png",
    imgPathBack: "/images/imgPathBack_Page_030.png",
    haltung: 2,
    quadrant: 4
  }, {
    id: 81,
    imgPathFront: "/images/imgPathFront_Page_046.png",
    imgPathBack: "/images/imgPathBack_Page_046.png",
    haltung: 3,
    quadrant: 4
  }, {
    id: 82,
    imgPathFront: "/images/imgPathFront_Page_062.png",
    imgPathBack: "/images/imgPathBack_Page_062.png",
    haltung: 4,
    quadrant: 4
  }, {
    id: 83,
    imgPathFront: "/images/imgPathFront_Page_078.png",
    imgPathBack: "/images/imgPathBack_Page_078.png",
    haltung: 5,
    quadrant: 4
  }, {
    id: 84,
    imgPathFront: "/images/imgPathFront_Page_094.png",
    imgPathBack: "/images/imgPathBack_Page_094.png",
    haltung: 6,
    quadrant: 4
  }, {
    id: 85,
    imgPathFront: "/images/imgPathFront_Page_015.png",
    imgPathBack: "/images/imgPathBack_Page_015.png",
    haltung: 1,
    quadrant: 4
  }, {
    id: 86,
    imgPathFront: "/images/imgPathFront_Page_031.png",
    imgPathBack: "/images/imgPathBack_Page_031.png",
    haltung: 2,
    quadrant: 4
  }, {
    id: 87,
    imgPathFront: "/images/imgPathFront_Page_047.png",
    imgPathBack: "/images/imgPathBack_Page_047.png",
    haltung: 3,
    quadrant: 4
  }, {
    id: 88,
    imgPathFront: "/images/imgPathFront_Page_063.png",
    imgPathBack: "/images/imgPathBack_Page_063.png",
    haltung: 4,
    quadrant: 4
  }, {
    id: 89,
    imgPathFront: "/images/imgPathFront_Page_079.png",
    imgPathBack: "/images/imgPathBack_Page_079.png",
    haltung: 5,
    quadrant: 4
  }, {
    id: 90,
    imgPathFront: "/images/imgPathFront_Page_095.png",
    imgPathBack: "/images/imgPathBack_Page_095.png",
    haltung: 6,
    quadrant: 4
  }, {
    id: 91,
    imgPathFront: "/images/imgPathFront_Page_016.png",
    imgPathBack: "/images/imgPathBack_Page_016.png",
    haltung: 1,
    quadrant: 4
  }, {
    id: 92,
    imgPathFront: "/images/imgPathFront_Page_032.png",
    imgPathBack: "/images/imgPathBack_Page_032.png",
    haltung: 2,
    quadrant: 4
  }, {
    id: 93,
    imgPathFront: "/images/imgPathFront_Page_048.png",
    imgPathBack: "/images/imgPathBack_Page_048.png",
    haltung: 3,
    quadrant: 4
  }, {
    id: 94,
    imgPathFront: "/images/imgPathFront_Page_064.png",
    imgPathBack: "/images/imgPathBack_Page_064.png",
    haltung: 4,
    quadrant: 4
  }, {
    id: 95,
    imgPathFront: "/images/imgPathFront_Page_080.png",
    imgPathBack: "/images/imgPathBack_Page_080.png",
    haltung: 5,
    quadrant: 4
  }, {
    id: 96,
    imgPathFront: "/images/imgPathFront_Page_096.png",
    imgPathBack: "/images/imgPathBack_Page_096.png",
    haltung: 6,
    quadrant: 4
  }
]

export const randomPositions = [
  [
    2,
    4,
    6,
    3,
    5,
    1
  ],
  [
    2,
    5,
    3,
    6,
    1,
    4
  ],
  [
    5,
    4,
    6,
    1,
    2,
    3
  ],
  [
    5,
    2,
    6,
    4,
    3,
    1
  ],
  [
    2,
    5,
    6,
    1,
    3,
    4
  ],
  [
    5,
    1,
    3,
    2,
    6,
    4
  ],
  [
    5,
    6,
    4,
    1,
    3,
    2
  ],
  [
    6,
    2,
    4,
    5,
    3,
    1
  ],
  [
    3,
    2,
    6,
    5,
    1,
    4
  ],
  [
    6,
    5,
    2,
    1,
    4,
    3
  ],
  [
    4,
    5,
    2,
    3,
    6,
    1
  ],
  [
    2,
    4,
    1,
    3,
    5,
    6
  ],
  [
    2,
    3,
    1,
    6,
    5,
    4
  ],
  [
    3,
    1,
    5,
    2,
    6,
    4
  ],
  [
    1,
    4,
    3,
    6,
    2,
    5
  ],
  [
    3,
    5,
    6,
    1,
    2,
    4
  ],
  [
    1,
    3,
    2,
    6,
    5,
    4
  ],
  [
    3,
    4,
    1,
    6,
    2,
    5
  ],
  [
    5,
    3,
    1,
    2,
    4,
    6
  ],
  [
    4,
    1,
    3,
    6,
    2,
    5
  ],
  [
    6,
    1,
    4,
    3,
    5,
    2
  ],
  [
    3,
    6,
    4,
    1,
    5,
    2
  ],
  [
    4,
    3,
    6,
    5,
    2,
    1
  ],
  [
    4,
    6,
    5,
    3,
    1,
    2
  ],
  [
    2,
    3,
    1,
    6,
    5,
    4
  ],
  [
    1,
    6,
    2,
    4,
    3,
    5
  ],
  [
    4,
    1,
    3,
    2,
    6,
    5
  ],
  [
    1,
    6,
    4,
    5,
    3,
    2
  ],
  [
    3,
    2,
    4,
    6,
    1,
    5
  ],
  [
    4,
    6,
    5,
    1,
    3,
    2
  ],
  [
    1,
    2,
    6,
    4,
    3,
    5
  ],
  [
    3,
    4,
    1,
    6,
    5,
    2
  ],
  [
    2,
    6,
    1,
    3,
    4,
    5
  ],
  [
    5,
    6,
    4,
    3,
    2,
    1
  ],
  [
    2,
    4,
    5,
    3,
    6,
    1
  ],
  [
    5,
    2,
    4,
    6,
    1,
    3
  ],
  [
    2,
    5,
    3,
    1,
    6,
    4
  ],
  [
    3,
    6,
    2,
    5,
    1,
    4
  ],
  [
    6,
    1,
    5,
    4,
    2,
    3
  ],
  [
    2,
    1,
    5,
    4,
    3,
    6
  ],
  [
    2,
    5,
    1,
    4,
    3,
    6
  ],
  [
    5,
    3,
    4,
    6,
    2,
    1
  ],
  [
    4,
    1,
    2,
    5,
    6,
    3
  ],
  [
    4,
    6,
    1,
    5,
    3,
    2
  ],
  [
    5,
    1,
    2,
    4,
    6,
    3
  ],
  [
    2,
    1,
    5,
    4,
    6,
    3
  ],
  [
    4,
    5,
    3,
    6,
    1,
    2
  ],
  [
    5,
    4,
    6,
    2,
    1,
    3
  ],
  [
    2,
    5,
    3,
    6,
    1,
    4
  ],
  [
    5,
    6,
    1,
    4,
    3,
    2
  ]
];



export default data;
