import i18next from "../../../utils/i18n"

function EntwicklungsImpulseVideo() {
  if (i18next.language === "de") {
    return <iframe title="entwicklungs-impulse" width="560" height="315" src="https://www.youtube.com/embed/KnUx7q_7zoc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  } else {
    return <iframe width="560" height="315" src="https://www.youtube.com/embed/oJPHkGhzZYk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  }
}

export { EntwicklungsImpulseVideo }
