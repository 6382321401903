import { useSpring, animated as a } from 'react-spring';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

function BackSides(props) {
  const { t } = useTranslation();

  if (props.results.length < 16) {
    return <div className=""></div>;
  } else
    return <div className="d-flex">
      <div className="margin-bottom">
        <div className="">
          <div className="margin-bottom">
            <div className="row margin-bottom">
              <div className="col-1-xl-1 offset-xl-2 col-md-1 offset-lg-2 offset-1 d-flex col-2 align-items-center">
                <img alt="Target" className="" src="/images/quadrant-1.png" />
              </div>
              <div className="col-9">
                <h3 className="">
                  {t("backsides__one__title")}

                </h3>
                <h2 className="scalabl-headers">
                  {t("backsides__one__text")}

                </h2>
              </div>

            </div>
            <div className="d-flex justify-content-center">
              {
                props.selbstEntwicklung.map((pic, index) => {
                  return <Pic key={pic.id} id={pic.id} imgPathFront={pic.imgPathFront} imgPathBack={pic.imgPathBack} />
                })
              }
            </div>

          </div>
          <div className="margin-bottom ">
            <div className="row margin-bottom">
              <div className="col-1-xl-1 offset-xl-2 col-md-1 offset-lg-2 offset-1 col-2 d-flex align-items-center">
                <img alt="Target" className="" src="/images/quadrant-2.png" />
              </div>
              <div className="col-9">
                <h3 className="">
                  {t("backsides__two__title")}

                </h3>
                <h2 className="scalabl-headers">
                  {t("backsides__two__text")}

                </h2>
              </div>

            </div>
            <div className="d-flex justify-content-center">
              {
                props.teamEntwicklung.map((pic, index) => {
                  return <Pic key={pic.id} id={pic.id} imgPathFront={pic.imgPathFront} imgPathBack={pic.imgPathBack} />
                })
              }
            </div>

          </div>
          <div className="margin-bottom ">
            <div className="row margin-bottom">
              <div className="col-1-xl-1 offset-xl-2 col-md-1 offset-lg-2 offset-1 col-2 d-flex align-items-center">
                <img alt="Target" className="" src="/images/quadrant-3.png" />
              </div>
              <div className="col-9">
                <h3 className="">
                  {t("backsides__three__title")}

                </h3>
                <h2 className="scalabl-headers">
                  {t("backsides__three__text")}

                </h2>
              </div>

            </div>
            <div className="d-flex justify-content-center">
              {
                props.strukturenUndProzesse.map((pic, index) => {
                  return <Pic key={pic.id} id={pic.id} imgPathFront={pic.imgPathFront} imgPathBack={pic.imgPathBack} />
                })
              }
            </div>

          </div>
          <div className="margin-bottom ">
            <div className="row margin-bottom">
              <div className="col-1-xl-1 offset-xl-2 col-md-1 offset-lg-2 offset-1 col-2 d-flex align-items-center">
                <img alt="Target" className="" src="/images/quadrant-4.png" />
              </div>
              <div className="col-9">
                <h3 className="">
                  {t("backsides__four__title")}

                </h3>
                <h2 className="scalabl-headers">
                  {t("backsides__four__text")}

                </h2>
              </div>

            </div>
            <div className="d-flex justify-content-center">
              {
                props.kulturEntwicklung.map((pic, index) => {
                  return <Pic key={pic.id} id={pic.id} imgPathFront={pic.imgPathFront} imgPathBack={pic.imgPathBack} />
                })
              }
            </div>

          </div>
        </div>
      </div>

    </div>

}

function Pic(props) {
  const [flipped, set] = useState(false)
  const { transform, opacity } = useSpring({
    opacity: flipped
      ? 1
      : 0,
    transform: `perspective(600px) rotateX(${flipped
      ? 180
      : 0}deg)`,
    config: {
      mass: 5,
      tension: 500,
      friction: 80
    }
  })
  return (<div className="resultBild" onClick={() => set(state => !state)}>
    <div className="center">
      <a.div className="position-absolute" style={{
        opacity: opacity.interpolate(o => 1 - o),
        transform
      }}>
        <img className="resultBild" alt="werteBild" src={props.imgPathBack} />
      </a.div>
      <a.div className="" style={{
        opacity,
        transform: transform.interpolate(t => `${t} rotateX(180deg)`)
      }}>
        <img className="resultBild " alt="werteBild" src={props.imgPathFront} />
      </a.div>
    </div>
  </div>)
}
export default BackSides;
