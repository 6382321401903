import React, { useEffect } from "react"
import Team from "./profileComponents/team"
import { logout } from "../actions/auth";
import { findTeams } from "../actions/team";
import { connect } from "react-redux";
import PropTypes from "prop-types"
import { Link } from "react-router-dom";



const Profile = ({ logout, teamNames, findTeams }) => {

    useEffect(() => {


        findTeams()


    }, [findTeams])

    return <div className="d-flex justify-content-center align-items-center">


        <div className="container row">
            <div className="avoidNav col-12 margin-bottom"></div>



            <div className="profile__previous__results col-12">

            </div>
            <h2>
                Your Teams
            </h2>
            <div className="profile__teams col-12 row">
                {
                    teamNames ? teamNames.map((teamName) => { return <Team teamName={teamName} /> }) : () => <h2>You are in no Teams yet</h2>
                }



            </div>
            <div className="ml-auto  profile__logout">
                <Link className="profile__team__link" to="/">
                    <div className=" login-button" onClick={logout}>
                        <p>
                            logout
                        </p>
                    </div>
                </Link>

            </div>
        </div>

    </div>
}
Profile.propTypes = {

    logout: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({
    teamNames: state.team.teamNames
})
export default connect(mapStateToProps, { logout, findTeams })(Profile);