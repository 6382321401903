import React, { useState, Fragment } from "react";
import { randomPositions } from "../data.js";
import { Redirect } from "react-router-dom";

import Gamecontrols from "./components/gamecontrols.jsx";
import Headline from "./components/headline.jsx";
import Anleitung from "./components/anleitung.jsx";

import { saveResult } from "../actions/result";
import { connect } from "react-redux";
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next";
import i18next from "../utils/i18n"
import dataEn from "../dataEn.js"
import dataDe from "../data.js"

let data;
if (i18next.language === "de") {
  data = dataDe
} else if (document.domain === "quickcheck.haltung-entscheidet.de") {
  data = dataDe
} else {
  data = dataEn
}

//stores selected ids
let results = [];


const shuffeledData = [];
function shuffleData() {
  for (let i = 0; i < 16; i++) {
    const randomElement = randomPositions[Math.floor(Math.random() * randomPositions.length)];
    randomElement.forEach(index => shuffeledData.push(data[i * 6 + index - 1]))
  }

}

shuffleData();

function Game({ isAuthenticated, saveResult }) {

  const { t } = useTranslation();

  //for which step
  const [step, setStep] = useState(0);
  //just used to display nextButton
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(true);


  // increases step value
  async function increase() {
    setStep(step + 1);
  }

  function restart() {
    results = [];
    setStep(0);
    setCount(0);
    shuffleData();
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  if (step === 16) {
    const data = results;
    const type = "singlePlayer"
    saveResult({ data, type, isAuthenticated })
    return <Redirect to="/result" />
  }
  if (step === 0 && results.length > 0) {
    restart()
  }

  // decreases step value and deletes previous results
  async function decrease() {
    if (results.length === (step + 1)) {
      for (let i = 0; i < 2; i++) {
        results.pop();
      }
    } else {
      results.pop();
    }

    setStep(step - 1);
  }


  if (loading === true) {
    setTimeout(() => setLoading(false), 4000)
    return <Fragment>
      <div className="game d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h2 className="white text-center margin-bottom">
            {t("game__loading__one")} <br /> {t("game__loading__two")}
          </h2>
          <p className="lead white text-center">{t("game__loading__three")}</p>
        </div>

      </div>
    </Fragment>
  } else {
    return <Fragment>

      <div className="game" id="game">

        <div className="avoidNav margin-bottom">

        </div>
        <div className="d-flex justify-content-center row">
          <div className="cotainer">
            <Headline step={step} />
          </div>

        </div>
        <div className="d-flex justify-content-center">


          <div className="d-flex row game__gameplay justify-content-center container ">

            <Gamecontrols increase={increase} decrease={decrease} count={count} step={step} />


            <div className="d-flex col-xl-8 col-lg-8 col-md-11 col-10  flex-wrap margin-bottom justify-content-center">
              {
                shuffeledData.map((pic, index) => {
                  if ((index < (step * 6 + 6)) && (index >= (step * 6))) {

                    if (pic.id === results[results.length - 1]) {
                      var selected = "selected"
                    } else {
                      selected = "notSelected"
                    }
                    return <Pic key={pic.id} increase={increase} id={pic.id} imgPath={pic.imgPathFront} selected={selected} step={step} count={count} setCount={setCount} />


                  } else {
                    return null
                  }
                })
              }
            </div>
          </div>
        </div>





        <div className="avoidNav"></div>
      </div>

      <Anleitung />
      <div className="d-flex justify-content-center w-100">
        <button onClick={restart} className="anleitung__link lead border-thick btn">
          {t("game__restart")}

        </button>
      </div>
    </Fragment >
  }
}

//##########
function Pic(props) {
  // const [selectedClass, setSelectedClass] = useState("notSelected");

  function toggle() {
    if ((props.step + 1) > results.length) {
      results.push(props.id)
      props.setCount(props.count + 1)

      props.increase()


      //change pictures here with setPictures
    } else {
      if (props.id === results[results.length - 1]) {
        results.pop();
        props.setCount(props.count - 1)
      } else {
        results.pop();
        props.setCount(props.count - 1)
        results.push(props.id)

        props.setCount(props.count + 1)
      }


    }
  }
  console.log(props.imgPath)
  return <div className="game__bild d-flex justify-content-center col-lg-4 col-6">
    <button className={props.selected} onClick={toggle}><img className="werteBild" alt="werteBild" src={props.imgPath} /></button>
  </div>
}


export {
  results
}

Game.propTypes = {
  saveResult: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { saveResult })(Game);
