import { REGISTER_TEAM_SUCCESS, SET_TEAM_NAME, REGISTER_TEAM_FAIL, LOGIN_TEAM_FAIL, LOGIN_TEAM_SUCCESS, TEAM_RESULTS_RECEIVED, USERS_TEAMS_FOUND } from "../actions/types"

const initialState = {
  teamName: null
}

export default function _(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REGISTER_TEAM_SUCCESS:
    case LOGIN_TEAM_SUCCESS:


      return {
        ...state,
        teamName: payload.team.name
      }
    case USERS_TEAMS_FOUND:
      return {
        ...state,
        teamNames: payload.teamNames
      }
    case TEAM_RESULTS_RECEIVED:
      return {
        ...state,

        teamResult: payload.team.result

      }
    case SET_TEAM_NAME:
      return {
        ...state,
        teamName: payload.teamName
      }
    case REGISTER_TEAM_FAIL:
    case LOGIN_TEAM_FAIL:
      return {
        ...state,
        teamName: null
      }
    default:
      return state
  }
}
