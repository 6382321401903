import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";


// We can localize to any language and any number of languages.
if (document.domain === "quickcheck.mindset-matters.info") {
    i18next
        .use(initReactI18next)
        .use(HttpApi)
        .use(LanguageDetector)// Registering the back-end plugin
        .init({
            // Remove resources from here

            supportedLngs: ["en"],
            interpolation: {
                escapeValue: false,
            },
            fallbackLng: "en",
            debug: process.env.NODE_ENV === "development",
        });
} else {
    i18next
        .use(initReactI18next)
        .use(HttpApi)
        .use(LanguageDetector)// Registering the back-end plugin
        .init({
            // Remove resources from here

            supportedLngs: ["de"],
            interpolation: {
                escapeValue: false,
            },
            fallbackLng: "de",
            debug: process.env.NODE_ENV === "development",
        });
}

export default i18next;