
// import { Redirect} from "react-router-dom";

// import { saveResult } from "../actions/result";
// import {connect} from "react-redux";
// import PropTypes from "prop-types"
import { useState } from "react";
import data from "../data.js";
import { Redirect } from "react-router-dom"
import { saveTeamResult } from "../actions/result";
import { connect } from "react-redux";
import PropTypes from "prop-types"
function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

shuffle(data)
let loslassenArray = []
let bewahrenArray = []
let einladenArray = []

function GameSelect({ isAuthenticated, saveTeamResult, teamName }) {
  const [step, setStep] = useState(0)
  const [start, setStart] = useState(true)
  const type = "multiplayer"

  if (step === 96) {
    const result = []
    result.push(...loslassenArray)
    result.push(10001)
    result.push(...bewahrenArray)
    result.push(20002)
    result.push(...einladenArray)
    saveTeamResult({ result, type, teamName })
    return <Redirect to="team-result" />

  }




  if (teamName === null) {

    return <Redirect to="team-login" />
  }

  if (start) {
    setStart(false)
    loslassenArray = []
    bewahrenArray = []
    einladenArray = []
  }





  function loslassen() {
    loslassenArray.push(data[step].id)
    setStep(step + 1)
  }
  function einladen() {
    einladenArray.push(data[step].id)

    setStep(step + 1)
  }
  function bewahren() {
    bewahrenArray.push(data[step].id)
    setStep(step + 1)
  }
  return <div className="game d-flex flex-column justify-content-center">
    <div className="d-flex justify-content-center align-items-center margin-bottom">
      <div className="container d-flex justify-content-center">
        <Pic imgPath={data[step].imgPathFront} />
      </div>

    </div>
    <div className="d-flex justify-content-center ">
      <button className=" gameSelect__button" onClick={loslassen}>
        loslassen
      </button>
      <button className=" gameSelect__button" onClick={bewahren}>
        bewahren
      </button>
      <button className=" gameSelect__button" onClick={einladen}>
        einladen
      </button>

    </div>
  </div>

}

function Pic(props) {
  return <div className="game__bild d-flex justify-content-center col-lg-4 col-6">
    <button className="selected"><img className="werteBild" alt="werteBild" src={props.imgPath} /></button>
  </div>
}

GameSelect.propTypes = {
  saveTeamResult: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  teamName: state.team.teamName
})

export default connect(mapStateToProps, { saveTeamResult })(GameSelect);



// import "../css/cardstack.css"
//
// import React, { useState } from 'react';
// import { useSprings, animated, interpolate } from 'react-spring';
// import { useGesture } from 'react-use-gesture';
//
// // These two are just helpers, they curate spring data, values that are later being interpolated into css
// const to = i => ({ x: 0, y: i * -2.5, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 })
// const from = i => ({ x: 0, rot: 0, scale: 0.1, y: 10000 })
// // This is being used down there in the view, it interpolates rotation and scale into a css transform
// const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`
//
// function GameSelect() {
//   const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
//   const [props, setProps] = useSprings(data.length/4, i => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above
//   // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
//   const bind = useGesture(({ args: [index], down, delta: [xDelta], distance, direction: [xDir], velocity }) => {
//     const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
//     const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
//     if (!down && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
//     setProps(i => {
//
//       if (index !== i) return// Condition fpr only changing first card
//       const isGone = gone.has(index)
//       const x = isGone ? (100 + window.innerWidth) * dir : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
//       const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
//       const scale = down ? 1.02 : 1 // Active cards lift up a bit
//       return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
//     })
//     if (!down && gone.size === data.length) setTimeout(() => <Redirect to="/result/select"/>, 1000)
//   })
//   // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
//   return <div className="cardstack">
//     <div className="avoidNav"></div>
//     <section id="cardstack">
//   {    props.map(({ x, y, rot, scale }, i) => (
//         <animated.div key={i} style={{ transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`) }}>
//           {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
//           <animated.div {...bind(i)} style={{ transform: interpolate([rot, scale], trans), backgroundImage: `url(${data[i].imgPathFront})` }} />
//         </animated.div>
//       ))}
//     </section>
//   </div>
//
//
// }
