import { getTeamResult } from "../actions/team";
import { setAlert } from "../actions/alert";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { useSpring, animated as a } from 'react-spring';
import data from "../data.js";
import PropTypes from "prop-types"
import LoadingAnimation from "./components/loadingAnimation";
import { Redirect } from "react-router-dom"


const TeamResult = ({ teamName, getTeamResult, teamResult }) => {

  const [arrays, setArrays] = useState({ loslassenArray: [1, 2, 3], bewahrenArray: [1, 2, 3], einladenArray: [1, 2, 3] })
  const { loslassenArray, bewahrenArray, einladenArray } = arrays


  const [users, setUsers] = useState([])
  function getTopValues(obj, topN) {
    var sortedEntries = Object.entries(obj).sort(function (a, b) { return b[1] - a[1] });
    var last = sortedEntries[topN - 1][1];
    var result = sortedEntries.filter(function (entry) {
      return entry[1] >= last;
    });
    return Object.fromEntries(result)
  }

  useEffect(async () => {

    async function fetchData() {
      await getTeamResult({ teamName })
    }
    fetchData()

    //sort image data
    data.sort(function (a, b) {
      return a.id - b.id || a.name.localeCompare(b.name);
    });

    //improve perfomance later

    //check that user don't count twice
    const counters = {
      loslassenCounter: {},
      bewahrenCounter: {},
      einladenCounter: {}
    }


    if (teamResult) {


      for (let i = teamResult.length - 1; i >= 0; i--) {
        if (users.includes(teamResult[i].user)) {
          continue
        } else {
          users.push(teamResult[i].user)
          console.log(users)
          let pushTo = "loslassenCounter"

          teamResult[i].data.forEach(datapoint => {
            if (datapoint === 10001) {
              pushTo = "bewahrenCounter"

            } else if (datapoint === 20002) {
              pushTo = "einladenCounter"

            } else {

              if (counters[pushTo][datapoint] == null) {
                counters[pushTo][datapoint] = 1
              } else {
                counters[pushTo][datapoint] += 1
              }

            }

          }
          )
        }
        // .forEach(key => {
        //   loslassenArray.push(key)
        // })
      }


      let loslassenArr = []
      let bewahrenArr = []
      let einladenArr = []
      let loslassenObj = getTopValues(counters["loslassenCounter"], 6)
      for (let key in loslassenObj) {
        loslassenArr.push(key)
      }


      let bewahrenObj = getTopValues(counters["bewahrenCounter"], 6)
      for (let key in bewahrenObj) {
        bewahrenArr.push(key)
      }
      let einladenObj = getTopValues(counters["einladenCounter"], 6)
      for (let key in einladenObj) {
        einladenArr.push(key)
      }

      setArrays({ loslassenArray: loslassenArr.slice(0, 6), bewahrenArray: bewahrenArr.slice(0, 6), einladenArray: einladenArr.slice(0, 6) })
    }
  }, [getTeamResult])

  if (!teamName) {
    return <Redirect to="/" />
  }
  if (typeof teamResult === "undefined") {
    return <LoadingAnimation />
  } else {




    return <div className="d-flex justify-content-center">

      <div className="container">
        <div className="avoidNav"></div>
        <h2 className="text-center">{teamName}`s Ergebnis</h2>
        <p>
          {`${users.length} team members participated to the consenis`}
        </p>
        <div className="row">
          <div className="col-12">
            <h2>Loslassen</h2>
            <div className="d-flex flex-wrap">
              {loslassenArray.map((id) => {
                return <Pic key={data[id].id} id={data[id].id} imgPathFront={data[id].imgPathFront} imgPathBack={data[id].imgPathBack} />
              })
              }
            </div>

          </div>
          <div className="col-12">
            <h2>Bewahren</h2>
            <div className="d-flex flex-wrap">
              {
                bewahrenArray.map((id) => {
                  return <Pic key={data[id].id} id={data[id].id} imgPathFront={data[id].imgPathFront} imgPathBack={data[id].imgPathBack} />
                })
              }
            </div>

          </div>
          <div className="col-12">
            <h2>Einladen</h2>
            <div className="d-flex flex-wrap">
              {
                einladenArray.map((id) => {
                  return (<Pic key={data[id].id} id={data[id].id} imgPathFront={data[id].imgPathFront} imgPathBack={data[id].imgPathBack} />)
                })
              }
            </div>

          </div>
        </div>

      </div>

    </div>
  }
}



function Pic(props) {
  const [flipped, set] = useState(false)
  const { transform, opacity } = useSpring({
    opacity: flipped
      ? 1
      : 0,
    transform: `perspective(600px) rotateX(${flipped
      ? 180
      : 0}deg)`,
    config: {
      mass: 5,
      tension: 500,
      friction: 80
    }
  })
  return (<div className="resultBild" onClick={() => set(state => !state)}>
    <div className="center">
      <a.div className="position-absolute" style={{
        opacity: opacity.interpolate(o => 1 - o),
        transform
      }}>
        <img className="resultBild" alt="werteBild" src={props.imgPathFront} />
      </a.div>
      <a.div className="" style={{
        opacity,
        transform: transform.interpolate(t => `${t} rotateX(180deg)`)
      }}>
        <img className="resultBild " alt="werteBild" src={props.imgPathBack} />
      </a.div>
    </div>
  </div>)
}
TeamResult.propTypes = {
  getTeamResult: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({ isAuthenticated: state.auth.isAuthenticated, teamName: state.team.teamName, teamResult: state.team.teamResult })

export default connect(mapStateToProps, { setAlert, getTeamResult })(TeamResult)
