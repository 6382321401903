import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { login } from "../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next";
const Login = ({ login, isAuthenticated }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ email: "", password: "" });

  const { email, password } = formData;

  //For every Form thingy thorugh e.target.value
  const onChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password)

  }

  // REDIRECT if loged in
  if (isAuthenticated) {
    return <Redirect to="/" />
  }
  return <Fragment>
    <div className="avoidNav margin-bottom">

    </div>
    <div className="d-flex justify-content-center">
      <section className="container">
        <h5 className=" text-center">{t("login__title")}</h5>
        <p className="text-center margin-bottom">{t("login_subtitle")}</p>
        <p className="text-center margin-bottom-big">
          {t("login__text")}</p>
        <form className="form text-center" onSubmit={e => onSubmit(e)}>
          <div className="form-group">
            <input type="email" placeholder={t("login__placeholder__one")} name="email" value={email} onChange={e => onChange(e)} />
          </div>
          <div className="form-group">
            <input type="password" placeholder={t("login__placeholder__two")} name="password" minLength="6" value={password} onChange={e => onChange(e)} />
          </div>
          <div className="d-flex justify-content-center">
            <input type="submit" className="btn header-button margin-bottom-big white" value={t("login__button")} />
          </div>

        </form>
        <p className="my-1 text-center">
          {t("login__to__register")}
          <Link to="/register">{t("login__to__register__bold")}</Link>
        </p>
      </section>
    </div>

  </Fragment>
}
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { login })(Login)
