import axios from "axios";
import { setAlert } from "./alert";
import {
    REGISTER_SUCCES,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT
} from "./types";
import setAuthToken from "../utils/setAuthToken"


//LOAD USER
export const loadUser = () => async dispatch => {

    if (localStorage.token) {
        setAuthToken(localStorage.token)
    }

    try {
        const res = await axios.get("api/auth");

        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch (error) {
        dispatch({
            type: AUTH_ERROR
        })

    }
}
// REGISTER USER

export const register = ({ name, email, password }) => async dispatch => {
  console.log(name , email, password)
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    // Getting body
    const body = JSON.stringify({ name, email, password });
    console.log(body)
    try {
        // getting response
        const res = await axios.post("/api/user", body, config);
        dispatch({
            type: REGISTER_SUCCES,
            payload: res.data
        });
        dispatch(loadUser());


    } catch (err) {

        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: REGISTER_FAIL
        })
    }

}

// LOGIN USER

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    // Getting body

    const body = JSON.stringify({ email, password });


    try {
        // getting response
        const res = await axios.post("/api/auth", body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        dispatch(loadUser());

    } catch (err) {

        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: LOGIN_FAIL
        })
    }

}

//LOGOUT /Clear Profile

export const logout = () => dispatch => {
  console.log("noice")
    dispatch({ type: LOGOUT })

}
