
const options = {
  scale: {
    pointLabels: {
      display: false
    },
    ticks: {
      display: false,
      min: 0,
      max: 7,
      stepsize: 1
    }
  },
  legend: {
    display: false
  },
  title: {
    display: false
  },
  tooltips: {
    // Disable the on-canvas tooltip
    enabled: false,

    // custom: function(tooltipModel) {
    //   // Tooltip Element
    //   var tooltipEl = document.getElementById('chartjs-tooltip');
    //
    //   // Create element on first render
    //   if (!tooltipEl) {
    //     tooltipEl = document.createElement('div');
    //     tooltipEl.id = 'chartjs-tooltip';
    //     tooltipEl.innerHTML = '<table></table>';
    //     document.body.appendChild(tooltipEl);
    //   }
    //
    //   // Hide if no tooltip
    //   if (tooltipModel.opacity === 0) {
    //     tooltipEl.style.opacity = 0;
    //     return;
    //   }
    //
    //   // Set caret Position
    //   tooltipEl.classList.remove('above', 'below', 'no-transform');
    //   if (tooltipModel.yAlign) {
    //     tooltipEl.classList.add(tooltipModel.yAlign);
    //   } else {
    //     tooltipEl.classList.add('no-transform');
    //   }
    //
    //   function getBody(bodyItem) {
    //     return bodyItem.lines;
    //   }
    //
    //   // Set Text
    //   if (tooltipModel.body) {
    //     var titleLines = tooltipModel.title || [];
    //     var bodyLines = tooltipModel.body.map(getBody);
    //
    //     var titles = [];
    //     titleLines.forEach(function(title) {
    //       titles.push(title)
    //     });
    //     var style = null;
    //     //set Styles
    //     bodyLines.forEach(function(body, i) {
    //       var colors = tooltipModel.labelColors[i];
    //       style = 'background:' + colors.backgroundColor;
    //       style += '; border-color:' + colors.borderColor;
    //       style += '; border-width: 2px';
    //     });
    //
    //     var innerHtml = "<div><span style=" + style + "><h1>Haus</h1></span></div>"
    //     var tableRoot = tooltipEl.querySelector('table');
    //     tableRoot.innerHTML = innerHtml;
    //   }
    //
    //   // `this` will be the overall tooltip
    //   var position = this._chart.canvas.getBoundingClientRect();
    //
    //   // Display, position, and set styles for font
    //   tooltipEl.style.opacity = 1;
    //   tooltipEl.style.position = 'absolute';
    //   tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    //   tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    //   tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    //   tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    //   tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    //   tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    //   tooltipEl.style.pointerEvents = 'none';
    // }
  },
  animation: {
    delay: 1000,
    duration: 3000,
    animateRotate: true,
    animateOutwards: true
  },
  startAngle: -0.19,
  layout: {
    padding: {
      top: 25,
      bottom: 25,
      left: 25,
      right: 25,

    }
  }
}
export default options;
