// import NextButton from "./nextButton.jsx";
import BackButton from "./backButton.jsx";


function Gamecontrols(props) {

  return <div newrender={props.step} className="d-flex align-items-center col-1 flex-column gamecontrols ">
    <a className="order-10" href="#Anleitung">
      <div className="gameplay__link"><h2 className="text-green text-center game__go-down">?</h2></div>
      <img className="game__go-down__button" src="/images/go-down.png" alt="Third slide" />
    </a>

    <div className=" order-5">
      <BackButton decrease={props.decrease} step={props.step} />
    </div>

    <div className="progress__bar">
      <div className={"progress__bar--progress progress-" + (props.step)}>
      </div>

    </div>

    {/* <NextButton increase={props.increase} step={props.step} count={props.count} /> */}
  </div>
}

export default Gamecontrols;
