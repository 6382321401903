import { useTranslation } from "react-i18next";


function Headline(props) {
  const { t } = useTranslation();

  if (props.step < 4) {
    return <div className="d-flex offset-md-2 offset-1 col-10">
      <img className="margin-right h-75" alt="Target" src="/images/quadrant-light.png" />
      <h2 className="white game__headline">{t("headline__question__one")}</h2>
    </div>
  } else if (props.step >= 4 && props.step < 8) {
    return <div className="d-flex offset-md-2 offset-1 col-10">
      <img className="margin-right h-75 quadrant-2" alt="Target" src="/images/quadrant-light.png" />
      <h2 className="white  game__headline">{t("headline__question__two")}</h2>
    </div>
  } else if (props.step >= 8 && props.step < 12) {
    return <div className="d-flex offset-md-2 offset-lg-3 col-lg-8 offset-1 col-10 col-md-9">
      <img className="margin-right quadrant-3 h-75" alt="Target" src="/images/quadrant-light.png" />
      <h2 className="white  game__headline">{t("headline__question__three")}</h2>
    </div>
  } else if (props.step >= 12 && props.step < 16) {
    return <div className="d-flex offset-md-2 col-md-9 offset-1 col-10">
      <img className="margin-right quadrant-4 h-75" alt="Target" src="/images/quadrant-light.png" />
      <h2 className="white  game__headline">{t("headline__question__four")}</h2>
    </div>
  } else {
    return null;
  }
}

export default Headline
