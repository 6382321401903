import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../actions/alert";
import PropTypes from "prop-types";
import { register } from "../actions/auth";
import { useTranslation } from "react-i18next";

const Register = ({ setAlert, register, isAuthenticated }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ name: "", email: "", password: "", password2: "" });

  const { name, email, password, password2 } = formData;

  const onChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value
  })

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      //Alert message and type
      setAlert("Passwörter stimmen nicht über ein!", "danger")

    } else {
      register({ name, email, password })
    }
  }
  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return <div>
    <div className="avoidNav margin-bottom"></div>
    <div className="d-flex justify-content-center">
      <section className="container">
        <h2 className="text-center"> {t("register__title")}</h2>
        <p className="margin-bottom-big text-center">
          {t("register__text")}</p>
        <form className="form text-center" onSubmit={e => onSubmit(e)}>
          <div className="form-group">
            <input type="text" placeholder={t("register__placeholder__one")} name="name" required="required" value={name} onChange={e => onChange(e)} />
          </div>
          <div className="form-group">
            <input type="email" placeholder={t("register__placeholder__two")} name="email" value={email} onChange={e => onChange(e)} />

          </div>
          <div className="form-group">
            <input type="password" placeholder={t("register__placeholder__three")} name="password" minLength="6" value={password} onChange={e => onChange(e)} />
          </div>
          <div className="form-group">
            <input type="password" placeholder={t("register__placeholder__four")} name="password2" minLength="6" value={password2} onChange={e => onChange(e)} />
          </div>
          <div className="d-flex justify-content-center">
            <input type="submit" className="btn header-button margin-bottom-big white" value={t("register__button")} />
          </div>
        </form>

        <p className="my-1 text-center">
          {t("register__to__login")}
          <Link to="/login"> {t("register__to__login__bold")}</Link>
        </p>
        {/* <p className="my-1 text-center">
          Du willst ein Team registrieren?
          <Link to="/team-register"> Team registrieren</Link>
        </p> */}


      </section>
    </div>
  </div>
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})
export default connect(mapStateToProps, { setAlert, register })(Register)
