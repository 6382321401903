import {connect} from "react-redux";
import React, {useState} from "react";

import {Redirect, Link} from "react-router-dom"
import PropTypes from "prop-types";
import {loginTeam} from "../actions/team";
import {setAlert} from "../actions/alert";

const LoginTeam = ({setAlert, loginTeam, isAuthenticated, teamName}) => {

  const [formData, setFormData] = useState({name: "", email: "", password: "", password2: ""});

  const {name, password} = formData;

if (typeof teamName === "string") {
  return <Redirect to="game-select" />
}
  const onChange = e => setFormData({
    ...formData,
    [e.target.name]: e.target.value
  })

  const onSubmit = async e => {
    e.preventDefault();
    if (!isAuthenticated) {
      setAlert("Du musst eingeloggt sein um dich in ein Team einzuloggen.", "danger")
    } else {
      loginTeam({name, password})

    }
  }
  return <div>
    <div className="avoidNav margin-bottom"></div>
    <div className="d-flex justify-content-center">
      <section className="container">
        <h2 className="text-center">Jetzt mit Team Einloggen</h2>
        <p className="margin-bottom-big text-center">
          Hier Einloggen</p>
        <form className="form text-center" onSubmit={e => onSubmit(e)}>
          <div className="form-group">
            <input type="text" placeholder="Team-Name" name="name" required="required" value={name} onChange={e => onChange(e)}/>
          </div>
          <div className="form-group">
            <input type="password" placeholder="Passwort" name="password" minLength="6" value={password} onChange={e => onChange(e)}/>
          </div>
          <div className="d-flex justify-content-center">
            <input type="submit" className="btn header-button margin-bottom-big white" value="Einloggen"/>
          </div>
        </form>
        <p className="my-1 text-center">
          Du hast noch kein Team?
          <Link to="/team-register"> Registrieren</Link>
        </p>
      </section>

    </div>
  </div>
}

LoginTeam.propTypes = {
  loginTeam: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({isAuthenticated: state.auth.isAuthenticated, teamName: state.team.teamName})

export default connect(mapStateToProps, {setAlert, loginTeam})(LoginTeam)
