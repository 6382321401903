import React from "react"
import { Link } from "react-router-dom";
import { setTeamName } from "../../actions/team";
import { connect } from "react-redux";
import PropTypes from "prop-types"

const Team = ({ teamName, setTeamName }) => {
    function setTeamNameCaller() {
        console.log(teamName)
        setTeamName(teamName)
    }
    return <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-center align-items-center" onClick={setTeamNameCaller}>
        <Link className="profile__team__link" to="/team-result">
            <div className="profile__team d-flex justify-content-center align-items-center">
                <h3 className="profile__team__name">{teamName}</h3>

            </div>
        </Link>


    </div>
}


Team.propTypes = {

    setTeamName: PropTypes.func.isRequired,

}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, { setTeamName })(Team);