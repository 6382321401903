import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLinkedin, faTwitter, faInstagram, faFacebook, faYoutube, faXing, faPinterest, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return <footer className="footer">
    <div className="container">
      <hr />
      <div className="row margin-bottom-huge">
        <div className="col-offset-1 col-5">
          <p className="footer__text bold margin-bottom">
            {t("footer__title")}


          </p>
          <p className="footer__text">
            {t("footer__text__light__one")}

            <span className="bold">           {t("footer__text__bold__one")}</span>
            {t("footer__text__light__two")}

            <span className="bold"> {t("footer__text__bold__two")}</span>
            {t("footer__text__light__three")}

          </p>
        </div>
        <div className="d-flex col-6 flex-wrap">
          <a href="https://ich-wir-alle.com/alle-folgen" target="_blank" rel="noopener noreferrer" className="margin-right d-flex">
            <FontAwesomeIcon className="margin-right-small" icon={faSpotify} />
            <p className="footer__text">
              ICH WIR ALLE
            </p>
          </a>
          <a href="https://www.instagram.com/shortcutsbln/" target="_blank" rel="noopener noreferrer" className="margin-right d-flex">
            <FontAwesomeIcon className="margin-right-small" icon={faInstagram} />
            <p className="footer__text">
              @shortcutsbln
            </p>
          </a>

          <a href="https://www.facebook.com/agentur.shortcuts/" target="_blank" rel="noopener noreferrer" className="margin-right d-flex">
            <FontAwesomeIcon className="margin-right-small" icon={faFacebook} />
            <p className="footer__text">
              @agentur.short-cuts
            </p>
          </a>

          <a href="https://www.linkedin.com/company/shortcutsbln" target="_blank" rel="noopener noreferrer" className="margin-right d-flex">
            <FontAwesomeIcon className="margin-right-small" icon={faLinkedin} />
            <p className="footer__text">
              shortcutsbln
            </p>
          </a>
          <a href="https://www.youtube.com/user/AgenturShortCuts" className="margin-right d-flex">
            <FontAwesomeIcon className="margin-right-small" icon={faYoutube} />
            <p className="footer__text">
              AgenturShortCuts
            </p>
          </a>
          <a href="https://www.xing.com/pages/shortcutsgmbhdesign-kommunikation" target="_blank" rel="noopener noreferrer" className="margin-right d-flex">
            <FontAwesomeIcon className="margin-right-small" icon={faXing} />
            <p className="footer__text">
              shortcuts
            </p>
          </a>
          <a href="https://twitter.com/shortcutsberlin/" target="_blank" rel="noopener noreferrer" className="margin-right d-flex">
            <FontAwesomeIcon className="margin-right-small" icon={faTwitter} />
            <p className="footer__text">
              @shortcutsberlin
            </p>
          </a>

        </div>

      </div>
    </div>
  </footer >
}

export default Footer
