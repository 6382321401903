import { Link } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types"
import { logout } from "../../actions/auth";

import { useTranslation } from "react-i18next";


function Heading({ auth: { isAuthenticated, loading, logout }, title }) {
  const { t } = useTranslation();


  let variable2 = "item-hover"
  let variable3 = <h4 className="item-hover">{t("nav__two")}</h4>
  let variableX = ""

  switch (title) {
    case "Haltung Erweitern":

      variableX = "item-line d-none"
      break;

    case "Erfahre Mehr":

      variable2 = "item-hover item-chosen"
      variableX = "item-line d-flex"
      break;

    case "Spiel":

      variable3 = <h4 className="item-hover item-chosen">{t("nav__two")}</h4>
      variableX = "item-line d-none"
      break;

    case "Dein Ergebnis":

      variable3 = <h4 className="item-hover item-chosen">{t("nav__two")}</h4>
      variableX = "item-line d-none"
      break;

    default:
      break;
  }


  let auth = <Link className="nav-link login-button" to="/login"><div className="">
    <h4>Login</h4>
  </div>
  </Link>

  let game = <Link className="text-dec nav-link wrapper-line" to="/game"><div className="nav-link">{variable3}</div>
  </Link>

  if (isAuthenticated) {
    auth = <Link className="profile__team__link" to="/">
      <div className=" login-button" onClick={logout}>
        <p >
          Logout
        </p>
      </div>
    </Link>

    game = <Link className="text-dec nav-link wrapper-line" to="/game/me">
      <div className="">{variable3}</div>
    </Link>
  }




  //<nav class="navbar navbar-expand-lg navbar-light bg-light">
  //    <a class="navbar-brand" href="#">Navbar</a>
  //    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
  //      <span class="navbar-toggler-icon"></span>
  //    </button>
  //    <div class="collapse navbar-collapse" id="navbarNav">
  //      <ul class="navbar-nav">
  //        <li class="nav-item active">
  //          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
  //        </li>
  //        <li class="nav-item">
  //          <a class="nav-link" href="#">Features</a>
  //        </li>
  //        <li class="nav-item">
  //          <a class="nav-link" href="#">Pricing</a>
  //        </li>
  //        <li class="nav-item">
  //          <a class="nav-link disabled" href="#">Disabled</a>
  //        </li>
  //      </ul>
  //    </div>
  //  </nav>

  return <div className="">
    <nav className="w-100 row navbar navbar-expand-lg navbar-light">
      <div className="col-lg-1 col-xl-2 hide-menu-placeholder"></div>

      <Link className="navbar-brand responsive-margin-brand" to="/">
        <img alt="Icon" className="icon" src="/images/_neu1/Haltung_erweitern_logo_shads.png" />
      </Link>

      {/* <li className="d-lg-none center">
        <Link className="nav-link" to="/game">
          <div className="text-center">
            {variable3}
          </div>
        </Link>
      </li> */}


      <button className="navbar-toggler col-1 responsive-margin-burger" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="burger">
          <img alt="Icon" className="fleischundsalat" src="/images/_neu1/burger.png" />
        </span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="p-0 navbar-nav mr-auto col">


          <li className="nav-item">
            <Link className="text-dec nav-link  wrapper-line" to="/erfahre-mehr">
              <h4 className={variable2}>{t("nav__one")}</h4>
              <div className={variableX}></div>
            </Link>
          </li>

          <li className="nav-item">
            {game}
          </li>


          <li className="nav-item">
            {auth}
          </li>

          <li className="nav-item header-button-new">
            <a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://shop.short-cuts.de/collections/kartenspiele/products/kartenspiel-zur-erweiterung-der-inneren-haltung">
              <h4 className="white text-center">{t("nav__four")}</h4>
            </a>
          </li>






        </ul>
      </div>
      <div className="col-lg-1 col-xl-2 hide-menu-placeholder"></div>
    </nav>
  </div>
}

Heading.propTypes = {

  auth: PropTypes.object.isRequired,

}
const mapStateToProps = state => ({
  auth: state.auth,
})
export default connect(mapStateToProps, { logout })(Heading);
