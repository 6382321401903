import { useTranslation } from "react-i18next";
export default function ShopPreview() {
    const { t } = useTranslation()
    return <div className="margin-bottom-big">
        <h2 className="text-center margin-bottom"> {t("shopPreview__title")}</h2>
        <div className="d-flex justify-content-center ">

            <div className="container row d-flex justify-content-center">
                <div className="col-lg-4 col-sm-6 col-10 d-flex justify-content-center margin-bottom">
                    <div>
                        <a href={t("shopPreview__link__1")} target="_blank" rel="noreferrer">
                            <img src={t("shopPreview__img__1")} alt="Hey" className="shopPreview__img margin-bottom" />

                        </a>

                        <p className="margin-bottom">
                            {t("shopPreview__description__1")}
                        </p>
                        <a href={t("shopPreview__link__1")} target="_blank" rel="noreferrer" className="shopPreview__button white">
                            {t("shopPreview__button")}
                        </a>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-10 d-flex justify-content-center margin-bottom">
                    <div>
                        <a href={t("shopPreview__link__2")} target="_blank" rel="noreferrer">
                            <img src={t("shopPreview__img__2")} alt="Hey" className="shopPreview__img margin-bottom" />

                        </a>

                        <p className="margin-bottom">
                            {t("shopPreview__description__2")}
                        </p>
                        <a href={t("shopPreview__link__2")} target="_blank" rel="noreferrer" className="shopPreview__button white">
                            {t("shopPreview__button")}
                        </a>
                    </div>

                </div>
                <div className="col-lg-4 col-sm-6 col-10 d-flex justify-content-center">

                    <div>
                        <a href={t("shopPreview__link__3")} target="_blank" rel="noreferrer">
                            <img src={t("shopPreview__img__3")} alt="Hey" className="shopPreview__img margin-bottom" />

                        </a>

                        <p className="margin-bottom">
                            {t("shopPreview__description__3")}
                        </p>
                        <a href={t("shopPreview__link__3")} target="_blank" rel="noreferrer" className="shopPreview__button white">
                            {t("shopPreview__button")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

