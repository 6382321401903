import {
    SAVE_RANDOM_RESULT,
    SAVE_USER_RESULT,
    SAVE_TEAM_RESULT
} from "../actions/types"

const initialState = [{
  result: {}
}]

export default function _(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SAVE_RANDOM_RESULT:
            return [...state, payload];
        case SAVE_USER_RESULT:
            return [...state, payload];
          case SAVE_TEAM_RESULT:
            return [...state, payload];
        default:
            return state;
    }
}
