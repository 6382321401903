import { useTranslation } from "react-i18next";

function BackButton(props) {
  const { t } = useTranslation();

  if (props.step === 0) {
    return <button className="btn btn-hover-grey greyColor">            {t("gamecontrols__previous")}
    </button>
  } else {
    return <button className="btn btn-hover-white white" onClick={props.decrease}>{t("gamecontrols__previous")}</button>
  }
}

export default BackButton;
