import axios from "axios";
import { setAlert } from "./alert";
import {
    REGISTER_TEAM_SUCCESS,
    REGISTER_TEAM_FAIL,
    LOGIN_TEAM_FAIL,
    LOGIN_TEAM_SUCCESS,
    TEAM_RESULTS_RECEIVED,
    TEAM_RESULTS_RECEIVED_FAILED,
    USERS_TEAMS_FOUND,
    USERS_TEAMS_FOUND_FAILED,
    SET_TEAM_NAME
} from "./types";


export const registerTeam = ({ name, password }) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    // Getting body
    const body = JSON.stringify({ name, password });
    try {
        // getting response
        const res = await axios.post("/api/team", body, config);
        dispatch({
            type: REGISTER_TEAM_SUCCESS,
            payload: res.data
        });


    } catch (err) {

        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: REGISTER_TEAM_FAIL
        })
    }

}
export const loginTeam = ({ name, password }) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    const body = JSON.stringify({ name, password });
    try {

        // getting response
        const res = await axios.post("/api/team/login", body, config);

        dispatch({
            type: LOGIN_TEAM_SUCCESS,
            payload: res.data
        });


    } catch (err) {

        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        }
        dispatch({
            type: LOGIN_TEAM_FAIL
        })
    }
}
export const getTeamResult = ({ teamName }) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    const body = JSON.stringify({ teamName });
    console.log(body)

    try {
        const res = await axios.post("/api/team/result", body, config)


        dispatch({
            type: TEAM_RESULTS_RECEIVED,
            payload: res.data
        });


    } catch (err) {

        console.log(err)
        // const errors = err.response.data.errors;

        // if (errors) {
        //     errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        // }

        dispatch({
            type: TEAM_RESULTS_RECEIVED_FAILED
        })

    }
}
export const findTeams = () => async dispatch => {
    try {
        const res = await axios.get("/api/team/getuser")

        dispatch({
            type: USERS_TEAMS_FOUND,
            payload: res.data
        });
    } catch (error) {

        // const errors = error.response.data.errors;

        // if (errors) {
        //     errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
        // }

        dispatch({
            type: USERS_TEAMS_FOUND_FAILED
        })

    }
}

export const setTeamName = (teamName) => async dispatch => {

    console.log(teamName)
    dispatch({
        type: SET_TEAM_NAME,
        payload: { teamName }
    })
}