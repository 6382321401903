import { results } from "./game.jsx";
import { Polar } from "react-chartjs-2";
import React, { useState } from 'react';



import BackSides from "./components/backsides.jsx";
import LoadingAnimation from "./components/loadingAnimation.jsx";
import options from "./components/options.js";
import Wissensdrang from "./components/wissensdrang.jsx";
import ShopPreview from "./components/shopPreview.jsx";
import html2pdf from "html2pdf.js"
import { useTranslation } from "react-i18next";

import i18next from "../utils/i18n"
import dataEn from "../dataEn.js"
import dataDe from "../data.js"




// import Goo from "./components/goo.jsx"

const sektoren = [
  "Haltung",
  "Biografische Entwicklung",
  "Paradigma",
  "Situative Haltung",
  "Beziehung",
  "Handlungsimpuls",
  "Teamspirit",
  "Bewusstsein",
  "Denkstruktur",
  "Innovationskultur",
  "Organigram",
  "Organisationsfrom",
  "Arbbeitswelt",
  "Kommunikationskultur",
  "Führungsstil",
  "Führungshaltung"
]
let data;
let target__path;
if (i18next.language === "de") {
  data = dataDe
  target__path = "/images/target2.jpg";

} else if (document.domain === "quickcheck.haltung-entscheidet.de") {
  data = dataDe
  target__path = "/images/en/target2.jpg";
} else {
  data = dataEn
  target__path = "/images/en/target2.jpg";
}


const today = new Date()
function Result() {


  const { t } = useTranslation();

  const sortedData = data.sort(function (a, b) {
    return a.id - b.id || a.name.localeCompare(b.name);
  });
  const finalResults = [];

  for (let i = 0; i < results.length; i++) {
    finalResults.push(sortedData[results[i] - 1])
  }

  const selbstEntwicklung = []
  const teamEntwicklung = []
  const strukturenUndProzesse = []
  const kulturEntwicklung = []

  // Assigning to Sectors
  const haltungen = []
  for (let i = 0; i < 4; i++) {
    selbstEntwicklung.push(finalResults[i])
  }
  for (let i = 4; i < 8; i++) {
    teamEntwicklung.push(finalResults[i])
  }
  for (let i = 8; i < 12; i++) {
    strukturenUndProzesse.push(finalResults[i])
  }
  for (let i = 12; i < 16; i++) {
    kulturEntwicklung.push(finalResults[i])
  }

  finalResults.forEach(item => haltungen.push(item.haltung + 1))

  function pdfDownload() {
    let pdfElement = document.createElement("div")
    let pdfHead = document.createElement("div")

    pdfHead.innerHTML = `<div class="w-100 position-absolute" style="top: 20px; display: flex; justify-content: center; align-items: flex-start; position: absolute">
    <div> <h1 class="text-center" >${t("pdf__title")}</h1>
    <p class="text-center margin-bottom" >${today.toDateString()}</p>
    <div class="flex justify-content-center">
    <img class="d-block mr-auto ml-auto margin-bottom" style="width: 300px" src=${target__path} alt="Second slide" />
    </div>
    


    <div style="display: flex; justify-content: center;">
<div  class="margin-right" >
<p class="text-center margin-bottom" >${t("pdf__category__one")}</p>
<div style="display: flex; justify-content: center;">
<img class="d-block shadow__pdf" style="height: 125px;" src=${selbstEntwicklung[0].imgPathBack} alt="Second slide" />
<img class="d-block shadow__pdf" style="height: 125px;" src=${selbstEntwicklung[1].imgPathBack} alt="Second slide" />
<img class="d-block shadow__pdf" style="height: 125px;" src=${selbstEntwicklung[2].imgPathBack} alt="Second slide" />
<img class="d-block shadow__pdf" style="height: 125px;" src=${selbstEntwicklung[3].imgPathBack} alt="Second slide" />
</div>
<div style="display: flex; justify-content: center;">
<img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${selbstEntwicklung[0].imgPathFront} alt="Second slide" />
<img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${selbstEntwicklung[1].imgPathFront} alt="Second slide" />
<img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${selbstEntwicklung[2].imgPathFront} alt="Second slide" />
<img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${selbstEntwicklung[3].imgPathFront} alt="Second slide" />
</div>
</div>



    <div>
    <p class="text-center margin-bottom" >${t("pdf__category__two")}</p>
    <div style="display: flex; justify-content: center;">
    <img class="d-block shadow__pdf" style="height: 125px;" src=${teamEntwicklung[0].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${teamEntwicklung[1].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${teamEntwicklung[2].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${teamEntwicklung[3].imgPathBack} alt="Second slide" />
    </div>
    <div style="display: flex; justify-content: center;">
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${teamEntwicklung[0].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${teamEntwicklung[1].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${teamEntwicklung[2].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${teamEntwicklung[3].imgPathFront} alt="Second slide" />
    </div>
    </div>

    </div>
    <div style="display: flex; justify-content: center; position: relative; bottom: 30px;">

    <div  class="margin-right" >
    <p class="text-center margin-bottom" >${t("pdf__category__three")}</p>
    <div style="display: flex; justify-content: center; margin-bottom: 0px;">
    <img class="d-block shadow__pdf" style="height: 125px;" src=${kulturEntwicklung[0].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${kulturEntwicklung[1].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${kulturEntwicklung[2].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${kulturEntwicklung[3].imgPathBack} alt="Second slide" />
    </div>
    <div style="display: flex; justify-content: center; margin-bottom: 0px;">
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${kulturEntwicklung[0].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${kulturEntwicklung[1].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${kulturEntwicklung[2].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${kulturEntwicklung[3].imgPathFront} alt="Second slide" />
    </div>
    </div>
  
    <div>
    <p class="text-center margin-bottom" >${t("pdf__category__four")}</p>
    <div style="display: flex; justify-content: center; margin-bottom: 0px;">
    <img class="d-block shadow__pdf" style="height: 125px;" src=${strukturenUndProzesse[0].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${strukturenUndProzesse[1].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${strukturenUndProzesse[2].imgPathBack} alt="Second slide" />
    <img class="d-block shadow__pdf" style="height: 125px;" src=${strukturenUndProzesse[3].imgPathBack} alt="Second slide" />
    </div>
    <div style="display: flex; justify-content: center; margin-bottom: 0px;">
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${strukturenUndProzesse[0].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${strukturenUndProzesse[1].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${strukturenUndProzesse[2].imgPathFront} alt="Second slide" />
    <img class="d-block" style="height: 125px; position: relative; bottom: 25px; z-index: -2" src=${strukturenUndProzesse[3].imgPathFront} alt="Second slide" />
    </div>
    
    </div>
    
    </div>
    <hr style="position: relative; bottom: 90px;"/>
    <div class="row" style="position: relative; bottom: 100px; display: flex; justify-content: around">
    <div class="col-4 offset-1">
    <p style="font-size: 12px;">https://short-cuts.de/</p>
    <p style="font-size: 12px;">https://www.haltung-entscheidet.de/</p>
    <p style="font-size: 12px;">https://ich-wir-alle.com/</p>
   </div>
    <div class="col-7">
          <p class="footer__text bold">

            inspirieren überzeugen erfreuen

          </p>
          <p class="footer__text">
          ${t("footer__text__light__one")}
            <span class="bold"> ${t("footer__text__bold__one")}</span>
            ${t("footer__text__light__two")}
            <span class="bold"> ${t("footer__text__bold__two")}</span>
            ${t("footer__text__light__three")}
          </p>
        </div>
   
    </div>

    </div>
    </div>`

    pdfElement.appendChild(pdfHead)
    html2pdf(pdfElement, {
      margin: 0,
      filename: "quickheck_haltung.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        letterRendering: true,
        height: 1050,
        scrollY: 0,
        scale: 4
      },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
  }

  const dataset = {
    labels: sektoren,
    datasets: [
      {
        label: "Dein Haltungsraum",
        borderJoinStyle: "round",
        borderColor: "white",
        borderWidth: "0px",
        backgroundColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          switch (value) {
            case 2:
              return "#e65014";
            case 3:
              return "#b99655";
            case 4:
              return "#5087c3";
            case 5:
              return "#f5a500";
            case 6:
              return '#87be2d';
            case 7:
              return "#2db4aa";
            default:
              return "black"
          }

        },
        data: haltungen
      }
    ]
  }
  const [loading, setLoading] = useState(true);
  if (loading === true) {
    setTimeout(() => setLoading(false), 2000)
    return <LoadingAnimation />
  } else {
    return <div>
      <div className="avoidNav margin-bottom"></div>
      <div className="">
        <div className="d-flex row justify-content-center items-align-center">
          <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-4 margin-bottom-big">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>

              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="d-block results__polar" id="chart">
                    <Polar width={410} height={410} data={dataset} options={options} />
                  </div>
                </div>
                <div className="carousel-item results__img">
                  <img className="d-block w-100" src={target__path} alt="Second slide" /></div>

              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>

          </div>
          <a className="col-12 text-center" href="#BackSides">
            <h2 className="margin-bottom">{t("result__title")}</h2>
            <img id="BackSides" className="result__go-down" src="/images/result-go-down.png" alt="go-down" />
          </a>

        </div>
        <div className="d-flex justify-content-center" >
          <BackSides results={results} selbstEntwicklung={selbstEntwicklung} teamEntwicklung={teamEntwicklung} kulturEntwicklung={kulturEntwicklung} strukturenUndProzesse={strukturenUndProzesse} />
        </div>
        <div className="d-flex justify-content-center margin-bottom-huge ">
          <button className="result-button white lead pdf__download" onClick={pdfDownload}>
            {t("result__download")}
          </button>
        </div>

        <ShopPreview />
        <Wissensdrang />
      </div>
      {/* <hr/>
      <div className="result__goo">
        <Goo />
      </div> */
      }


    </div>

  }
}
export default Result // {
//   finalResults.map((pic, index) => {
//     if ((index+1) % 4 === 0) {
//       const br = <br/>
//       console.log(br)
//     }
//     return <Pic key={pic.id} id={pic.id} imgPath={pic.imgPathBack}/>
//
//   })
// }
