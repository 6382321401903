import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "./utils/i18n";
// import ScrollToTop from './components/components/scrollToTop';

ReactDOM.render(
  <React.StrictMode>
    {/* <ScrollToTop> */}
    <React.Suspense fallback="Loading...">
      <App />
      {/* </ScrollToTop> */}
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
