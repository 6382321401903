function LoadingAnimation() {
  return <div className="loading m-60">
    <div className="haltung1"></div>
    <div className="haltung2"></div>
    <div className="haltung3"></div>
    <div className="haltung4"></div>
    <div className="haltung5"></div>
    <div className="haltung6"></div>
  </div>
}

export default LoadingAnimation;
