import {combineReducers} from "redux";
import alert from "./alert";
import auth from "./auth";
import result from "./result";
import team from "./team";

export default combineReducers({
  alert,
  auth,
  result,
  team
});
