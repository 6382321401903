import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './App.css';
import './css/profile.css'
import './css/gameSelect.css'
import Game from './components/game.jsx';
import GameSelect from './components/gameSelect.jsx';
import Result from './components/results.jsx';
import Home from './components/home.jsx';
import ErfahreMehr from './components/erfahreMehr.jsx';
import Heading from './components/components/header.jsx';
import Footer from './components/components/footer.jsx';
import Login from './components/login.jsx';
import Register from './components/register.jsx';
import RegisterTeam from './components/register-team.jsx';
import LoginTeam from './components/login-team.jsx';
import TeamResult from './components/team-result.jsx';
import Alert from './components/components/alert.jsx';
import MetaTags from "./components/metaTags";
import Profile from "./components/profile.jsx"

import PageNavigationListener from "./utils/PageNavigationListener.jsx";

//redux
import { Provider } from "react-redux";
import store from "./store";


function App() {
  return (<Provider store={store}>
    <Router>
      <PageNavigationListener />
      <div className="App">
        <MetaTags></MetaTags>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */
        }
        <Switch>

          <Route path="/game">
            <Heading title="Spiel" />
            <Game />
            <Footer />
          </Route>
          <Route path="/game-select">
            <Heading title="Spiel" />
            <GameSelect />

          </Route>
          <Route path="/game">
            <Heading title="Spiel" />
            <Game />
            <Footer />
          </Route>
          <Route path="/login">
            <Heading title="Login" />
            <Alert />
            <Login />
            <Footer />
          </Route>

          <Route path="/register">
            <Heading title="Register" />
            <Alert />
            <Register />
            <Footer />
          </Route>
          <Route path="/team-register">
            <Heading title="Register" />
            <Alert />
            <RegisterTeam />
            <Footer />
          </Route>
          <Route path="/team-login">
            <Heading title="Login" />
            <Alert />
            <LoginTeam />
            <Footer />
          </Route>
          <Route path="/team-result">
            <Heading title="Dein Ergebnis" />
            <TeamResult />
            <Footer />
          </Route>
          <Route path="/result">
            <Heading title="Dein Ergebnis" />
            <Result />
            <Footer />
          </Route>
          <Route path="/profile">
            <Heading title="Profile" />
            <Profile />
            <Footer />
          </Route>
          <Route path="/erfahre-mehr">
            <Heading title="Erfahre Mehr" />
            <ErfahreMehr />
            <Footer />
          </Route>
          <Route path="/">
            <Heading title="Haltung Erweitern" />
            <Home />
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  </Provider>);
}

export default App;
