import React from 'react';
import MetaTags from 'react-meta-tags';
import { useTranslation } from "react-i18next";

function MetaTagsComponent() {
    const { t } = useTranslation();
    return (
        <div className="wrapper">
            <MetaTags>
                <title>{t("meta__title")}</title>
                <meta id="meta-description" name="description" content={t("meta__description")} />
                <meta id="og-description" property="og:description" content={t("meta__description")} />
                <meta id="og-title" property="og:title" content={t("meta__title")} />

                <meta id="og-image" property="og:image" content="/favicon-32x32.png" />
            </MetaTags>

        </div>
    )
}


export default MetaTagsComponent