import { SAVE_RANDOM_RESULT, SAVE_USER_RESULT, SAVE_FAILED, SAVE_TEAM_RESULT } from "./types";
import axios from "axios";

export const saveResult = ({data, type, isAuthenticated}) => async dispatch => {


  if (isAuthenticated) {
    try {
      const config = {
          headers: {
              "Content-Type": "application/json"
          }
      }
      const body = JSON.stringify({ data, type });


      const res = await axios.post("/api/game/me", body, config);
      dispatch({
          type: SAVE_USER_RESULT,
          payload: res.data
      })
    } catch (error) {
      dispatch({
          type: SAVE_FAILED,
      })
    }

  } else {

    try {
      const body = JSON.stringify({ data, type });

      const config = {
          headers: {
              "Content-Type": "application/json"
          }
      }

      const res = await axios.post("/api/game", body, config);

      await dispatch({

          type: SAVE_RANDOM_RESULT,
          payload: res.data,
      })

    } catch (error) {
      dispatch({
          type: SAVE_FAILED,
      })
    }
}


}

export const saveTeamResult = ({result, type, teamName}) => async dispatch => {
  const data = result
  const config = {
      headers: {
          "Content-Type": "application/json"
      }
  }
  const body = JSON.stringify({ data, type, teamName });
  try {
    const res = await axios.post("/api/game/team", body, config);

    await dispatch({

        type: SAVE_TEAM_RESULT,
        payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: SAVE_FAILED
    })
  }
}
