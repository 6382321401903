import { useTranslation } from "react-i18next";
import React from "react"
import i18next from "../../utils/i18n"

function Anleitung() {
  const { t } = useTranslation();

  let target__path;
  if (i18next.language === "de") {
    target__path = "/images/target2.jpg"
  } else {
    target__path = "/images/en/target2.jpg"
  }

  return <section id="Anleitung">
    <div className="anleitung d-flex justify-content-center">
      <div className="row container">
        <div className="anleitung__margin">
          <h2 className="col-10 offset-1">
            {t("anleitung__entwicklung__title")}
          </h2>
          <p className="col-10 offset-1">
            {t("anleitung__entwicklung__text")}
          </p>
        </div>

        <div className="img-magnifier-container">
          <img alt="Target" className="col-10 offset-1 anleitung__margin" src={target__path} />
        </div>

        <h2 className="col-10 offset-1">
          {t("anleitung__quadrants__title")}

        </h2>
        <p className="col-10 offset-1 anleitung__margin">
          {t("anleitung__quadrants__text")}

        </p>
        <div className="offset-md-1 col-md-5 d-flex margin-bottom margin-right">
          <div className="margin-right align-self-center">
            <img alt="Target" className="" src="/images/quadrant-1.png" />

          </div>
          <div className="">
            <h5 className="">
              {t("anleitung__quadrant__one__title")}

            </h5>
            <p>
              {t("anleitung__quadrant__one__text")}

            </p>
          </div>
        </div>
        <div className=" col-md-5 d-flex margin-bottom margin-right">
          <div className="margin-right align-self-center">
            <img alt="Target" className="" src="/images/quadrant-3.png" />
          </div>
          <div className="">
            <h5 className="">
              {t("anleitung__quadrant__two__title")}

            </h5>
            <p>
              {t("anleitung__quadrant__two__text")}
            </p>
          </div>
        </div>
        <div className="offset-md-1 col-md-5 d-flex margin-bottom margin-right">
          <div className="margin-right align-self-center">
            <img alt="Target" className="" src="/images/quadrant-2.png" />
          </div>
          <div className="">
            <h5 className="">
              {t("anleitung__quadrant__three__title")}

            </h5>
            <p>
              {t("anleitung__quadrant__three__text")}

            </p>
          </div>
        </div>
        <div className=" col-md-5 d-flex margin-bottom margin-right">
          <div className="margin-right align-self-center">
            <img alt="Target" className="" src="/images/quadrant-4.png" />
          </div>
          <div className="">
            <h5 className="">
              {t("anleitung__quadrant__four__title")}


            </h5>
            <p>
              {t("anleitung__quadrant__four__text")}

            </p>
          </div>
        </div>

      </div>

    </div>
  </section>

}

export default Anleitung;
