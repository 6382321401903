import { useTranslation } from "react-i18next";


function Wissensdrang() {
  const { t } = useTranslation();

  return <div className="offset-1 col-lg-7 col-11 col-md-8 col-xl-5 offset-xl-2 margin-bottom-big">
    <h2 className="">{t("wissensdrang__question")}</h2>
    <p className="lead">
      {t("wissensdrang__text")}<br />
      <a className="lead" href="https://www.haltung-entscheidet.de/"> {t("wissensdrang__link")}</a>
    </p>
  </div>
}

export default Wissensdrang;
