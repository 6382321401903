// import Goo from "./components/goo.jsx"
import ImageSlider from "./components/imageSlider.jsx";
import Wissensdrang from "./components/wissensdrang.jsx";
import { EntwicklungsImpulseVideo } from "./components/videos/entwicklungsImpulse.js";
import { Fragment } from "react";

import { useTranslation } from "react-i18next";

function ErfahreMehr() {
  const { t } = useTranslation();

  return <Fragment>
    <div className="avoidNav"></div>
    <div className="d-flex justify-content-center">
      <div className="row container">
        <div className="offset-lg-1 col-lg-10 offset-0 col-12 margin-bottom-huge">
          <ImageSlider />
        </div>
        <a className="col-12 text-center" href="#Explanation">
          <h2 className="margin-bottom">{t("erfahreMehr__title")}</h2>
          <img className="result__go-down" src="/images/result-go-down.png" alt="go-down" />
          <hr id="Explanation" className="margin-bottom-big" />
        </a>

        <div className="offset-lg-1 col-lg-10 offset-0 col-12">
          <h2 className="text-green margin-bottom">
            {t("erfahreMehr__journey__title")}
          </h2>
          <p className="margin-bottom-big">
            {t("erfahreMehr__journey__text")}
          </p>
          <div className="d-flex justify-content-center margin-bottom-huge">
            <EntwicklungsImpulseVideo />
          </div>
        </div>
        <div>
          <div className="margin-bottom-huge">
            <div className="selbstorientiert d-flex align-items-center flex-lg-row flex-column margin-bottom-gigantic">
              <div className="offset-lg-1 col-lg-6 col-12 offset-0 order-lg-0 order-1">
                <h2 className="white"> {t("erfahreMehr__mindset__one__title")}</h2>
                <p className="white">
                  {t("erfahreMehr__mindset__one__text")}
                </p>
              </div>
              <img className="order-0 oder-lg-5 haltungs-slider__icon" src="/images/selbstorientiert-1.png" alt="go-down" />
              <img className="order-8" src="/images/selbstorientiert-2.png" alt="go-down" />
            </div>
            <div className="gemeinschaftsbestimmt d-flex align-items-center flex-lg-row flex-column margin-bottom-gigantic">

              <div className=" col-lg-6 col-12 offset-0 offset-lg-1 order-lg-2 order-1">
                <h2 className="white">{t("erfahreMehr__mindset__two__title")}</h2>
                <p className="white">
                  {t("erfahreMehr__mindset__two__text")}
                </p>
              </div>
              <img className="order-8 order-lg-0 margin-right" src="/images/gemeinschaftsbestimmt-2.png" alt="go-down" />
              <img className="order-0 oder-lg-1 haltungs-slider__icon" src="/images/gemeinschaftsbestimmt-1.png" alt="go-down" />
            </div>
            <div className="rationalistisch selbstorientiert d-flex align-items-center flex-lg-row flex-column margin-bottom-gigantic">
              <div className="offset-lg-1 col-lg-6 col-12 offset-0  order-lg-0 order-1">
                <h2 className="white">   {t("erfahreMehr__mindset__three__title")}
                </h2>
                <p className="white">
                  {t("erfahreMehr__mindset__three__text")}
                </p>
              </div>
              <img className="order-0 oder-lg-5 haltungs-slider__icon" src="/images/rationalistisch-1.png" alt="go-down" />
              <img className="order-8" src="/images/rationalistisch-2.png" alt="go-down" />
            </div>
            <div className="gemeinschaftsbestimmt eigenbestimmt d-flex align-items-center flex-lg-row flex-column margin-bottom-gigantic">

              <div className=" col-lg-6 col-12 offset-lg-1 offset-0 order-lg-2 order-1">
                <h2 className="white">   {t("erfahreMehr__mindset__four__title")}
                </h2>
                <p className="white">
                  {t("erfahreMehr__mindset__four__text")}
                </p>
              </div>
              <img className="order-8 order-lg-0 margin-right" src="/images/eigenbestimmt-2.png" alt="go-down" />
              <img className="order-0 oder-lg-1 haltungs-slider__icon" src="/images/eigenbestimmt-1.png" alt="go-down" />
            </div>
            <div className="selbstorientiert relativistisch d-flex align-items-center flex-lg-row flex-column margin-bottom-gigantic">
              <div className="offset-lg-1 col-lg-6 col-12 offset-0 order-lg-0 order-1">
                <h2 className="white">{t("erfahreMehr__mindset__five__title")}</h2>
                <p className="white">
                  {t("erfahreMehr__mindset__five__text")}
                </p>
              </div>
              <img className="order-0 oder-lg-5 haltungs-slider__icon" src="/images/relativistisch-1.png" alt="go-down" />
              <img className="order-8" src="/images/relativistisch-2.png" alt="go-down" />
            </div>
            <div className="systemisch gemeinschaftsbestimmt d-flex align-items-center flex-lg-row flex-column margin-bottom-gigantic">

              <div className=" col-lg-6 col-12 offset-0 offset-xl-1 order-lg-2 order-1">
                <h2 className="white">{t("erfahreMehr__mindset__six__title")}</h2>
                <p className="white">
                  {t("erfahreMehr__mindset__six__text")}
                </p>
              </div>
              <img className="order-8 order-lg-0 margin-right" src="/images/systemisch-2.png" alt="go-down" />
              <img className="order-0 oder-lg-1 haltungs-slider__icon" src="/images/systemisch-1.png" alt="go-down" />
            </div>

          </div>


        </div>
        <Wissensdrang />
      </div>
    </div>

    {/* <Goo /> */}

  </Fragment>
}

export default ErfahreMehr;
