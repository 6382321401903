import { Link } from "react-router-dom";
import { Fragment } from "react";
import i18next from "../utils/i18n"
// import HomeVideo from "./components/videos/homeVideo"
import { useTranslation } from "react-i18next";

function Home() {
  // const player = <Player autoPlay poster="/images/home.jpg" src="/videos/kartenspielV4.mp4">
  //   <ControlBar disableCompletely={true}/>
  // </Player>
  //
  // console.log(player)

  // player.play(player)
  let button__path;
  if (i18next.language === "de") {
    button__path = "/images/button.png"
  } else {
    button__path = "/images/en/button.png"
  }
  const { t } = useTranslation();

  return <Fragment>
    <div className="avoidNav"></div>
    <div className="d-flex container justify-content-center">

      <Link className="btn w-75 margin-bottom-big" to="/game">
        <img alt="button" className="w-100" src={button__path}></img>
      </Link>

    </div>
    <div className="container">
      <div className="row home__teaser">
        <h2 className=" col-lg-7 col-xl-8 offset-1 col-10">
          {t("home__play__now__title")}
        </h2>
        <p className=" col-lg-8 col-xl-8 offset-1 col-10 lead">
          {t("home__play__now__text")}
        </p>

      </div>
      <div className="row home__buttons d-flex justify-content-center">
        <div className="">
          <button className="home-nav__button border-thick">
            <Link className="no-decoration" to="/game">
              <img className="w-100 home-nav__button__img" alt="button" src="/images/home-button-1.png"></img>
              <div className="position-relative text-left home__button__text-1">
                <p className="lead">{t("home__button__one__light")}</p>
                <h2>{t("home__button__one__bold")}</h2>
              </div>

            </Link>
          </button>
        </div>

        <div className="">
          <button className="home-nav__button border-thick">
            <Link className="no-decoration" to="/erfahre-mehr">
              <img className="h-100 home-nav__button__img" alt="button" src="/images/home-button-2.png"></img>
              <div className="position-relative text-left home__button__text-2">
                <p className="lead">{t("home__button__two__light")}</p>
                <h2>{t("home__button__two__bold")}</h2>
              </div>

            </Link>
          </button>
        </div>
        <div className="">
          <button className="home-nav__button border-thick">
            <a target="_blank" rel="noopener noreferrer" className="no-decoration" href="https://shop.short-cuts.de/collections/kartenspiele/products/kartenspiel-zur-erweiterung-der-inneren-haltung">
              <img className="w-100 home-nav__button__img" alt="button" src="/images/home-button-3.png"></img>
              <div className="position-relative text-left home__button__text-3">
                <p className="lead">{t("home__button__three__light")}</p>
                <h2>{t("home__button__three__bold")}</h2>
              </div>

            </a>
          </button>
        </div>
      </div>
      <div className="row home__audience">
        <h2 className="col-lg-8 col-xl-7 offset-1 col-10">
          {t("home__for__who__title")}
        </h2>
        <p className=" col-lg-8 offset-1 col-10">
          {t("home__for__who__text")}
        </p>
      </div>
      {/* <div className="d-flex justify-content-center">
        <button className="home-nav__button border-thick">
          <Link className="no-decoration" to="/team-login">
            <img className="h-100 home-nav__button__img" alt="button" src="/images/home-button-2.png"></img>
            <div className="position-relative text-left home__button__text-2">
              <p className="lead">Mulitplayer</p>
              <h2>Modus</h2>
            </div>
          </Link>
        </button>
      </div> */}
    </div>

  </Fragment>

}

export default Home;
